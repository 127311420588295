 <template>
    <div>
        <Navbar :page="`Evento ${treinamento && treinamento.nome ? treinamento.nome : ''} `" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div v-if="treinamento" class="grid grid-cols-12 gap-6">
                <div v-if="this.treinamento.arte && this.treinamento.arte.mimetype" class="col-span-12 md:col-span-5 2xl:col-span-4">
                     <img v-if="this.treinamento.arte && this.treinamento.arte.mimetype" class="h-auto" :src="`${this.url_api}/upload?mimetype=${this.treinamento.arte.mimetype}&filename=${this.treinamento.arte.filename}&folder=empresas`" />
                </div>
                <div class="col-span-12 md:col-span-7 2xl:col-span-8">
                    <h3 class="font-semibold text-2xl mb-4">{{ treinamento.nome }}</h3>
                    <div class="grid grid-cols-12 gap-3">
                        <div class="col-span-12 md:col-span-7">
                            <h4 class="font-semibold text-lg py-1">{{ treinamento.tipo }}</h4>
                            <ul class="list-disc ml-4">
                                <li v-if="treinamento.transmissao && treinamento.transmissao.canalTransmissao" class="font-normal text-base py-1"> {{ treinamento.transmissao.canalTransmissao }}</li>
                                <li v-if="treinamento.transmissao && treinamento.transmissao.cargaHoraria" class="font-normal text-base py-1"> Carga horária: {{ treinamento.transmissao.cargaHoraria }}</li>
                                <li v-if="treinamento.transmissao && treinamento.transmissao.palestrante" class="font-normal text-base py-1"> Palestrante: <b>{{ treinamento.transmissao.palestrante }}</b></li>
                            </ul>
                            <p class="font-normal text-base py-3" v-html="treinamento.textoDoConvite"></p>
                        </div>
                        <div class="col-span-12 md:col-span-5">
                            <h4 class="font-semibold text-lg py-1">Certificado</h4>
                            <ul class="list-disc ml-4">
                                <li v-if="treinamento.certificado" class="font-normal text-base py-1"> Possuí certificado: {{ treinamento.certificado.naoIncluiCertificado ? 'Não' : 'Sim' }}</li>
                                <li v-if="treinamento.certificado && treinamento.certificado.envioCertificado && treinamento.certificado.envioCertificado.tipo" class="font-normal text-base py-1"> {{ treinamento.certificado.envioCertificado.tipo }}</li>
                            </ul>
                            <button v-if="convidado && !convidado.presenca" @click="confirmarPresenca()" type="button" class="block justify-center py-2 px-6 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                              Confirmar presença
                            </button>
                            <p class="text-base text-green-500 font-medium" v-else-if="convidado && convidado.presenca">Presença confirmada</p>
                            <button v-if="visualizarOpcaoProva()" @click="realizarProva()" type="button" class="block justify-center py-2 px-6 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-700">
                                {{ convidado.prova && convidado.prova.aplicada ? 'Resultado avaliação' : 'Realizar Avalição'}}
                            </button>
                            <a :href="`${api}/certificado/${convidado._id}`" target="_blank" v-if="visualizarCertificado()" type="button" class="inline-block justify-center py-2 px-6 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-indigo-600">
                            Certificado
                          </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            route: 'treinamentos',
            treinamento: null,
            convidado: null
        }
    },
    methods: {
        async start (){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

            if (id) {
                const req = await this.$http.get(`/v1/${this.route}/${id}`);
                this.treinamento = req.data;
            }

            const id_convidao = this.$route.query && this.$route.query.convidado ? this.$route.query.convidado.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            if(id_convidao) {
                const req = await this.$http.get(`/v1/${this.route}/convidados/${id_convidao}`);
                this.convidado = req.data;
            }
            

            let textoDoConvite = this.treinamento.textoDoConvite.replace(/\n/g, '<br>');
            textoDoConvite = textoDoConvite.replace('#nomedapessoa#', this.convidado.nome);
            textoDoConvite = textoDoConvite.replace('#nomedoevento#', this.treinamento.nome);
            textoDoConvite = textoDoConvite.replace('#data#', moment(this.treinamento.dataInicio).format('DD/MM/YYYY'));
            textoDoConvite = textoDoConvite.replace('#hora#', moment(this.treinamento.dataInicio).format('HH:mm'));
            textoDoConvite = textoDoConvite.replace('#canaltransmissao#', this.treinamento.transmissao.canalTransmissao);
            textoDoConvite = textoDoConvite.replace('#linkouendereco#', this.treinamento.transmissao.linkEndereco);

            this.treinamento.textoDoConvite = textoDoConvite;
        },
        async realizarProva() {
            const convidado = this.$route.query && this.$route.query.convidado ? this.$route.query.convidado.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/avaliacoes/nova-prova`,{ _id: convidado, treinamento: this.treinamento._id  });
            if(req.data.success && req.data.prova) {
                this.$router.push({path:`/treinamentos/answer/${req.data.prova._id}`});
            }else {
                this.$vToastify.error(req.data.err);
            }
        },
        async confirmarPresenca() {
            const req = await this.$http.post(`/v1/${this.route}/convidados/confirmarPresenca`, { _id: this.convidado });
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.start();
            } else {
                this.$vToastify.error(req.data.err);
            } 
        },
        visualizarOpcaoProva(){

            if(!this.treinamento) return false;
            if(!this.treinamento.avaliacao) return false;
            if(!this.convidado) return false;
            if(this.convidado.prova) return true;

            if(this.treinamento.envioAvaliacao.tipo === 'Logo após a conclusão do evento') {
                if(moment(this.treinamento.dataFim) < moment()) return true;
                else return false;
            }

            if(this.treinamento.envioAvaliacao.tipo === 'Programar para data específica') {
                if(moment(this.treinamento.envioAvaliacao.data) < moment()) return true;
                else return false;
            }

            if(this.treinamento.envioAvaliacao.tipo === 'Logo após o participante confirmar que assistiu') {
                if(this.convidado.presenca) return true;
                else return false;
            }

            return false;
        },
        visualizarCertificado(){
      
            if(!this.treinamento) return false;
            if(!this.treinamento.certificado) return false;
            if(this.treinamento.certificado.naoIncluiCertificado) return false;
            if(!this.convidado) return false;

            if(this.treinamento.certificado.envioCertificado.tipo === 'Programar para data específica') {
                if(moment(this.treinamento.certificado.envioCertificado.data) < moment()) return true;
                else return false;
            }
            if(this.treinamento.certificado.envioCertificado.tipo === 'Logo após a conclusão do teste') {
                if(this.convidado.prova) return true;
                else return false;
            }
            return false
        },
    },
    async beforeMount() {
        this.start();
    },
}
</script>
